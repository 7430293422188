/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'material-react-toastify';
import { formatDate, getUser } from '../../../config/common';
import { emailCase } from '../../../enums/emailCase';
import { getCompany } from '../../../services/company';
import { getAllEmployees } from '../../../services/employee';
import { sendEmail } from '../../../services/mail/sendMail';
import { approveSupport, disapproveSupport, getAllSupports } from '../../../services/support';

const Supports = () => {
    const [company, setCompany] = useState({});
    const [supports, setSupports] = useState([]);
    const [user, setUser] = useState({});
    const [employees, setEmployees] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [SupportPerPage] = useState(10);
    const [employee, setEmployee] = useState({});
    const id = window.location.pathname.split('/')[3];


    const toggleSupport = async (supportId, type) => {
        try {
            let response;

            if (type === 'approve') {

                response = await approveSupport(supportId);

            } else {

                response = await disapproveSupport(supportId);
            }

            if (!response.error) {

                if (type === 'approve') {
                    sendEmail(employee.emailAddress, employee.name, emailCase.approveSupport);
                    const newSupports = supports.map(leave => {
                        if (leave.id === supportId) {
                            leave.status = 'approve'
                        }
                        return leave;
                    });
                    setSupports(newSupports);
                } else {
                    const newSupports = supports.map(leave => {
                        if (leave.id === supportId) {
                            leave.status = 'disapprove'
                        }
                        return leave;
                    });
                    setSupports(newSupports);
                    sendEmail(employee.emailAddress, employee.name, emailCase.rejectSupport);
                }
                toast.info(response.message);
            }

        } catch (err) {
            toast.error("Error, try again");
        }

    };

    useEffect(() => {
        async function fetchData() {
            const user = await getUser();
            const response = await getCompany(id);
            const companyTickets = await getAllSupports(id);
            const employees = await getAllEmployees(id);
            if (response && companyTickets && user && employees) {
                setUser(user);
                setCompany(response);
                setEmployees(employees);
                setSupports(companyTickets);
            }
        }
        fetchData();
    }, [id]);

    console.log('okahy')

    const employeeSupport = async (employeeId) => {
        const employee = employees.find(employee => employee.id === employeeId);
        // setEmployee(employee); /// this is not working
        return employee;
    }

    const indexOfLastSupport = currentPage * SupportPerPage;
    const indexOfFirstSupport = indexOfLastSupport - SupportPerPage;
    const currentSupport = supports.slice(indexOfFirstSupport, indexOfLastSupport);

    const paginate = pageNumber => setCurrentPage(pageNumber);
    const nextPage = () => setCurrentPage(currentPage + 1);
    const prevPage = () => setCurrentPage(currentPage - 1);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(supports.length / SupportPerPage); i++) {
        pageNumbers.push(i);
    }


    return (
        <>
            <div>
                <div className='container'>
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center">
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item">
                                    <Link className="nav-link active">
                                        <i className="fa fa-arrow-left"></i>
                                    </Link>
                                </li>
                            </ul>
                            <div className="header-action">
                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i className="fe fe-plus mr-2" />Add</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`section-body mt-3`}>
                    <div className="container-fluid features ">
                        <div className='d-flex justify-content-between '>
                            <div className=" ">
                                <p className='text'>All Supports</p>
                            </div>
                        </div>
                        <div>
                            <div className="table-responsive ">
                                <table className="table table-vcenter text-nowrap mb-4 ">
                                    <thead>
                                        <tr>
                                            <th className="w60">Ticket</th>
                                            <th>details</th>
                                            <th>Customer name</th>
                                            <th>Date</th>
                                            <th>Priority</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {currentSupport.map((support, index) => (
                                            <tr key={index}>
                                                <td className="width45">
                                                    <span
                                                        className="avatar avatar-blue"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        data-original-title=""
                                                    >
                                                        {company.name[0]} {company.name[1]}
                                                    </span>
                                                </td>
                                                <td>
                                                    <h6 className="mb-0">{support.category}</h6>
                                                    <span>{support.note}</span>
                                                </td>
                                                <td>
                                                    <h6 className="mb-0">{employeeSupport(support.employee_id).name}</h6>
                                                    <span className="">{employeeSupport(support.employee_id).name}</span>
                                                </td>
                                                <td>
                                                    <h6 className="mb-0">{formatDate(support.createdAt)}</h6>
                                                </td>

                                                <td> {support.status === 'approve' && (
                                                    <span className="badge badge-success">approved</span>
                                                )}
                                                    {support.status === 'disapprove' && (
                                                        <span className="badge badge-danger">rejected</span>
                                                    )}
                                                    {support.status === 'pending' && (
                                                        <span className="badge badge-grey">pending</span>
                                                    )}
                                                </td>
                                                <td>
                                                    {support.status === 'approve' && (
                                                        <OverlayTrigger trigger="focus" placement="bottom" delay={1}
                                                            overlay={
                                                                <Popover id="popover-basic">
                                                                    <Popover.Header as="p">Confirm Decline</Popover.Header>
                                                                    <Popover.Body>
                                                                        <div className="clearfix" >
                                                                            <button style={{ margin: '10px' }} type="" className="btn btn-sm btn-success">Cancel</button>
                                                                            <button style={{ margin: '10px' }} onClick={() => toggleSupport(support.id, 'reject')} type="button" className="btn btn-sm btn-danger">Disapprove</button>
                                                                        </div>
                                                                    </Popover.Body>
                                                                </Popover>
                                                            }>
                                                            <button type="button" className="btn btn-icon js-sweetalert" title="Approve" data-type="confirm"><i className="fa fa-close text-danger" /></button>
                                                        </OverlayTrigger>
                                                    )}
                                                    {(support.status === 'pending' || support.status === 'disapprove') && (
                                                        <OverlayTrigger trigger="focus" placement="bottom" delay={1}
                                                            overlay={
                                                                <Popover id="popover-basic">
                                                                    <Popover.Header as="p">Confirm Approval</Popover.Header>
                                                                    <Popover.Body>
                                                                        <div className="clearfix" >
                                                                            <button style={{ margin: '10px' }} type="" className="btn btn-sm btn-success">Cancel</button>
                                                                            <button style={{ margin: '10px' }} onClick={() => toggleSupport(support.id, 'approve')} type="button" className="btn btn-sm btn-danger">Approve</button>
                                                                        </div>
                                                                    </Popover.Body>
                                                                </Popover>
                                                            }>
                                                            <button type="button" className="btn btn-icon js-sweetalert" title="Approve" data-type="confirm"><i className="fa fa-check text-success" /></button>
                                                        </OverlayTrigger>
                                                    )}
                                                </td>


                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className=''>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-end">
                                        <li className="page-item" style={{ marginRight: '5px' }}>
                                            <button className="btn btn-sm btn-primary" onClick={prevPage} disabled={currentPage === 1 ? true : false}>Previous</button>
                                        </li>
                                        {pageNumbers.map(number => (
                                            <li key={number} className="page-item" style={{ marginRight: '5px' }}>
                                                <button onClick={() => paginate(number)} className={currentPage === number ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-outline-primary'}>{number}</button>
                                            </li>
                                        ))}
                                        <li className="page-item">
                                            <button className="btn btn-sm btn-primary" onClick={nextPage} disabled={currentPage === pageNumbers.length ? true : false}>Next</button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
};


export default Supports