// Import all components here
// import React, { Component } from 'react'
import Dashboard from './Backend/Dashboard/Dashboard';
import Users from './Backend/Users/Users';
import Companies from './Backend/Companies/Companies';
import Company from './Backend/Companies/Company';
import Features from './Backend/Companies/Features';

import Tickets from './Backend/Companies/Tickets';

import Subscribers from './Backend/Subscribers/Subscribers';
import Demo from './Backend/Demo/Demo';
import Blog from './Backend/Blog/Blog';
import Event from './Backend/Event/Event';
import Mail from './Backend/Mail/Mail';

const Routes = [
    {
        path: "/",
        name: 'dashboard',
        exact: true,
        pageTitle: "HR Dashboard",
        component: Dashboard
    },
    {
        path: "/users",
        name: 'users',
        exact: true,
        pageTitle: "Users",
        component: Users
    },
    {
        path: "/companies",
        name: 'companies',
        exact: true,
        pageTitle: "Companies",
        component: Companies
    },
    {
        path: "/companies/company-dashboard/:id",
        name: 'company-dashboard',
        exact: true,
        pageTitle: "Company Dashboard",
        component: Company,
    },
    {
        path: "/companies/features/:id",
        name: 'features',
        exact: true,
        pageTitle: "Company Features",
        component: Features,
    },
    {
        path: "/companies/tickets/:id",
        name: 'features',
        exact: true,
        pageTitle: "Company Tickets",
        component: Tickets,
    },
    {

        path: "/subscribers",
        name: 'subscribers',
        exact: true,
        pageTitle: "Waiting List",
        component: Subscribers,
    },
    {

        path: "/demo",
        name: 'demo',
        exact: true,
        pageTitle: "demo",
        component: Demo,
    },
    {

        path: "/blog",
        name: 'blog',
        exact: true,
        pageTitle: "Blog",
        component: Blog,
    },
    {

        path: "/mail",
        name: 'mail',
        exact: true,
        pageTitle: "Mail",
        component: Mail,
    },
    {

        path: "/event",
        name: 'event',
        exact: true,
        pageTitle: "Events",
        component: Event,
    },



    

];

export default Routes;