import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUser } from "../../../config/common";
class Dashboard extends Component {

	render() {
		const { fixNavbar } = this.props;
		const user = getUser();
		return (
			<>
				<div>
					<div className={`section-body  mt-3`}>
						<div className="container-fluid">
							<div className="row clearfix">
								<div className="col-lg-12">
									<div className={`section-body mb-3`}>
										<h4>Welcome {user.name}</h4>
										{/* <small>
											Get realtime information about your company.{' '}
										</small> */}
									</div>
								</div>
							</div>
							<div className="row clearfix">
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body ribbon">
											<div className="ribbon-box green">5</div>
											<Link to="/hr-users" className="my_sort_cut text-muted">
												<i className="icon-users" />
												<span>Users</span>
											</Link>
										</div>
									</div>
								</div>
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body ribbon">
											<div className="ribbon-box green">5</div>
											<Link to="/companies" className="my_sort_cut text-muted">
												<i className="icon-users" />
												<span>Companies</span>
											</Link>
										</div>
									</div>
								</div>
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body">
											<Link to="/hr-holidays" className="my_sort_cut text-muted">
												<i className="icon-like" />
												<span>Holidays</span>
											</Link>
										</div>
									</div>
								</div>
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body ribbon">
											<div className="ribbon-box orange">8</div>
											<Link to="/hr-events" className="my_sort_cut text-muted">
												<i className="icon-calendar" />
												<span>Events</span>
											</Link>
										</div>
									</div>
								</div>
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body">
											<Link to="/hr-payroll" className="my_sort_cut text-muted">
												<i className="icon-credit-card" />
												<span>Payroll</span>
											</Link>
										</div>
									</div>
								</div>
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body">
											<Link to="/hr-accounts" className="my_sort_cut text-muted">
												<i className="icon-calculator" />
												<span>Accounts</span>
											</Link>
										</div>
									</div>
								</div>
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body">
											<Link to="/hr-report" className="my_sort_cut text-muted">
												<i className="icon-pie-chart" />
												<span>Report</span>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
const mapStateToProps = state => ({
	fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);