import request from 'umi-request';
import { API_URL, USER_TOKEN } from '../config/config';

export async function getAllBlogs() {
  return request(`${API_URL}/blogs/`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Authorization': USER_TOKEN,

    },
  });
}

export async function getBlog(id) {
    return request(`${API_URL}/blogs/blog/${id}`, {
      method: 'get',
      headers: {
         'Content-Type': 'application/json',
         'Authorization': USER_TOKEN,
      },
    });
  }

export async function createBlog(body, userId) {
  return request(`${API_URL}/blogs/create`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}

export async function updateBlog(body, blogId) {
  return request(`${API_URL}/blogs/update/${blogId}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}


export async function deleteBlog(blogId) {
  return request(`${API_URL}/blogs/delete/${blogId}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
    },
  });
}
