/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'material-react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { getAllCompanies, createCompany, deleteCompanyById } from '../../../services/company'
import { getUser } from '../../../config/common';
// import EditCompanies from './EditCompanies';
// import { sendEmail } from '../../../services/mail/sendMail';
// import { emailCase } from '../../../enums/emailCase';
import { Link, useHistory } from 'react-router-dom';
import activeFeatures from '../../../config/activeFeatures.json';
import { sendEmail } from '../../../services/mail/sendMail';
import { emailCase } from '../../../enums/emailCase';
import { createEmployee } from '../../../services/employee';
import { createNormalUser } from '../../../services/user';

const Companies = (navStatus) => {
	const [company, setCurrentUser] = useState([]);
	const [companies, setCompanies] = useState([]);
	// const [companyData, setCompanyData] = useState({});
	const [features, setFeatures] = useState(activeFeatures);
	const [formState, setFormState] = useState({
		name: '',
		registered_company_number: '',
		vat_number: '',
		email: '',
		address_line1: 'Lagos, Nigeria',
		address_line2: '',
		city: '',
		country: '',
		phone: '',
		company_logo: '',
		leave: false,
		payroll: false,
		employee: false,
		department: false,
		user: false,
		activity: false,
		events: false,
		reports: false,
		notification: false,
		expenseManagement: false,
		inventoryManagement: false,
		jobManagement: false,
		holidayManagement: false,
		projectManagement: false,
		currency: 'USD', // NGN
		timezone: '',
		dateFormat: 'DD/MM/YYYY',
		timeFormat: 'HH:mm',
		language: 'en',
		theme: 'light',
		password: '',
	});

	const history = useHistory();
	const createCompaniesAction = async () => {
		try {
			setFormState({ ...formState });
			const body = {
				name: formState.name,
				registered_company_number: formState.registered_company_number,
				incorporation_date: formState.incorporation_date,
				vat_number: formState.vat_number,
				email: formState.email,
				address_line1: formState.address_line1,
				address_line2: formState.address_line2,
				city: formState.city,
				country: formState.country,
				phone: formState.phone,
				company_logo: formState.logo,
				settings: {
					features: {
						leave: formState.leave,
						payroll: formState.payroll,
						employee: formState.employee,
						department: formState.department,
						user: formState.user,
						activity: formState.activity,
						events: formState.events,
						reports: formState.reports,
						notification: formState.notification,
						expenseManagement: formState.expenseManagement,
						inventoryManagement: formState.inventoryManagement,
						jobManagement: formState.jobManagement,
						holidayManagement: formState.holidayManagement,
						projectManagement: formState.projectManagement
					},
					currency: formState.currency,
					timezone: formState.timezone,
					dateFormat: formState.dateFormat,
					timeFormat: formState.timeFormat,
					language: formState.language,
					theme: formState.theme,
				}
			}

			if (body.name === '' || body.email === '') {
				toast.error('Please fill all the fields');
				return;
			}

			const response = await createCompany(body);

			const employeeData = {
				name: body.name,
				email: body.email,
				phone: formState.phone,
				address: formState.address,
				company_id: response.id,
				role: 'HR Manager',
				password: formState.password,
			}

			const employeeResponse = await createEmployee(employeeData);

			const userData = {
				employee_id: employeeResponse.id,
				name: formState.name,
				userName: formState.name,
				email: formState.email,
				phone: formState.phone,
				company_id: response.id,
				role: 'HR Manager',
				password: formState.password,
				confirmPassword: formState.password,
			}

			const userResponse = await createNormalUser(userData);

			if (response.id && employeeResponse.id && userResponse.id) {
				sendEmail(body.email, body.name, emailCase.userCreation); // implement email sending
				setCompanies([...companies, response])
				toast.success("Company created successfully");
			}

			setFormState({
				name: '',
				registered_company_number: '',
				vat_number: '',
				email: '',
				address_line1: '',
				address_line2: '',
				city: '',
				country: '',
				phone: '',
				company_logo: '',
				leave: false,
				payroll: false,
				employee: true,
				department: true,
				user: true,
				activity: true,
				events: false,
				reports: false,
				notification: true,
				expenseManagement: false,
				inventoryManagement: false,
				jobManagement: false,
				holidayManagement: false,
				projectManagement: false,
				currency: 'USD',
				timezone: '',
				date_format: 'DD/MM/YYYY',
				time_format: 'HH:mm',
				language: 'en',
				theme: 'light',
			});

		} catch (err) {
			toast.error("Error, try again");
			setFormState({ ...formState });

		};

	}


	const updateForm = (e) => {
		const { value, name } = e.target;
		if (name === 'email' || name === 'phone') {
			const emailExists = companies.find(company => company.email === value);
			const phoneExists = companies.find(company => company.phone === value);
			if (emailExists || phoneExists) {
				toast.error('Email or phone already exists');
				return;
			}
		}
		setFormState({
			...formState,
			[name]: value,
		})
	}

	function onChangeHandler(e) {
		const { value, name } = e.target;
		const active = value === 'true' ? true : false;
		let updatedFeatures = [...features];
		updatedFeatures = updatedFeatures.map((feature) => {
			if (feature.feature === name) {
				return { ...feature, active: active };
			} else {
				return feature;
			}
		});

		setFormState({ ...formState, [name]: active });

		setFeatures(updatedFeatures);
	}


	useEffect(() => {
		async function fetchData() {
			const company = await getUser();
			if (company) {
				const response = await getAllCompanies();
				setCompanies(response);
				setCurrentUser(company)
			}
		}
		fetchData();
	}, []);

	const navigateToCompanyDetails = (companyId) => {
		history.push(`/companies/company-dashboard/${companyId}`);
	}

	const uploadImage = (e) => {
		const { cdnUrl } = e;
		setFormState({
			...formState,
			'company_logo': cdnUrl,
		});
	};


	const deleteCompany = async (companyId) => {
		try { 
			if(!window.confirm('Are you sure you want to delete this company?')) return;
			const response = await deleteCompanyById(companyId);
			if (response) {
				const updatedCompanies = companies.filter(company => company.id !== companyId);
				setCompanies(updatedCompanies);
				toast.success('Company deleted successfully');
			}
		} catch (err) {
			toast.error('Error, try again');
		}
	}

	return (
		<>
			<div>
				<ToastContainer />
				<div className='container'>
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center">
							<ul className="nav nav-tabs page-header-tab">
								<li className="nav-item">

									<Link onClick={() => history.goBack()} className="nav-link active">
										<i className="fa fa-arrow-left"></i>
									</Link>
								</li>
							</ul>
							<div className="header-action">
								<button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i className="fe fe-plus mr-2" />Add</button>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-3">
					<div className="container-fluid">
						<div className="tab-content mt-3">
							<div className="tab-pane fade show active" id="company-list" role="tabpanel">
								<div>
									<div className="row clearfix row-deck">
										{companies.map((company) => (
											<div key={company.id} className="col-xl-4 col-lg-12 col-md-12">
												<div className="card">
													<div className="card-header">
														{/* <h3 className="card-title" style={{ fontWeight: 600 }}><Image
															src={require("../../../assets/images/hr-manager-logo.png")}
															alt={company.name}
															className="img-fluid"
															width={100}
														/></h3> */}
														<p>{company.name}</p>
														<div className="card-options">
															{/* <a href="/#" className="card-options-remove" data-toggle="card-remove">
															<i className="fe fe-x" />
														</a> */}

															<div className="item-action dropdown ml-2">
																<a href="fake_url" data-toggle="dropdown" aria-expanded="false">
																	<i className="fe fe-more-vertical" />
																</a>
																<div
																	className="dropdown-menu dropdown-menu-right"
																	x-placement="bottom-end"
																	style={{
																		position: 'absolute',
																		transform: 'translate3d(-174px, 25px, 0px)',
																		top: 0,
																		left: 0,
																		willChange: 'transform',
																	}}
																>
																	<a style={{ cursor: 'pointer' }} onClick={() => navigateToCompanyDetails(company.id)} className="dropdown-item">
																		<i className="dropdown-icon fa fa-eye" /> View Details{' '}
																	</a>
																	<a href={`tel:${company.phone}`} className="dropdown-item">
																		<i className="dropdown-icon fa fa-cloud-download" /> Call Admin
																	</a>
																	<div className="dropdown-divider" />
																	<a href={`mailto:${company.email}`} className="dropdown-item">
																		<i className="dropdown-icon fa fa-copy" /> Email Admin
																	</a>
																	<button onClick={()=> deleteCompany(company.id)} className="text-primary dropdown-item">
																		<i className="dropdown-icon fa fa-trash" /> Delete
																	</button>
																</div>
															</div>
														</div>

													</div>
													<div className='card-details'>
														
														<p className='card-details-title'>
															{company.createdAt.split('T')[0]}
														</p>
														<p className='card-details-title'>
															{company.email}
														</p>
														<p className='card-details-title'>
															{company.phone}
														</p>
														<p className='card-details-value'>
															{company.address_line1}
														</p>
													</div>
													<table className="table card-table mt-2">
														<tbody>
															<tr>
																<td className="width45">
																	{company.settings.features.leave ? (
																		<span className="avatar check-blue">
																			<i className="fa fa-check text-white feature-checker" />
																		</span>
																	) : (
																		<span className="avatar check-red">
																		</span>
																	)}
																</td>
																<td>
																	<span className="text-muted font-13">Leave Management</span>
																</td>
																<td className="text-right">
																	<span className="text-success font-13">{''}</span>
																</td>
															</tr>

															<tr >
																<td className="width45">
																	{company.settings.features.expenseManagement ? (
																		<span className="avatar check-blue">
																			<i className="fa fa-check text-white feature-checker" />
																		</span>
																	) : (
																		<span className="avatar check-red">
																		</span>
																	)}
																</td>
																<td>
																	<span className="text-muted font-13">Expense Management</span>
																</td>
																<td className="text-right">
																	<span className="text-success font-13">{''}</span>
																</td>
															</tr>

															<tr>
																<td className="width45">
																	{company.settings.features.employee ? (
																		<span className="avatar check-blue">
																			<i className="fa fa-check text-white feature-checker" />
																		</span>
																	) : (
																		<span className="avatar check-red">
																		</span>
																	)}
																</td>
																<td>
																	<span className="text-muted font-13">Employee Management</span>
																</td>
																<td className="text-right">
																	<span className="text-success font-13">{''}</span>
																</td>
															</tr>


														</tbody>
													</table>
												</div>
											</div>
										))}
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Modal */}
			<div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">Add Company</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className="card-body">
									<div className="row clearfix">
										<div className="col-lg-12 col-md-12 col-sm-12">
											<div className="form-group">
												<input
													name='name' value={formState?.name}
													onChange={updateForm}
													type="text"
													className="form-control"
													placeholder="Name *"
												/>
											</div>
										</div>
										<div className="col-lg-12 col-md-12 col-sm-12">
											<div className="form-group">
												<input
													name='email' value={formState?.email}
													onChange={updateForm}
													type="text"
													className="form-control"
													placeholder="Email ID *"
												/>
											</div>
										</div>
										<div className="col-lg-12 col-md-12 col-sm-12">
											<div className="form-group">
												<input
													name='phone'
													value={formState?.phone}
													onChange={updateForm}
													type="text"
													className="form-control"
													placeholder="Mobile No"
												/>
											</div>
										</div>
										<div className="form-group col-md-6">
											<div className="mb-3">
												<span className="text-danger">*</span>
												<input required className="form-control" name="registered_company_number"
													value={formState?.registered_company_number}
													onChange={updateForm}
													type="text"
													placeholder="Registered Company Number" />
											</div>
										</div>
										<div className="form-group col-md-6">
											<div className="mb-3">
												<span className="text-danger">*</span>
												<input required className="form-control datetimepicker"
													name="incorporation_date" type="date"
													value={formState?.incorporation_date}
													onChange={updateForm}
													placeholder="Incorp. Date"
												/>
											</div>
										</div>
										<div className="form-group col-md-6">
											<div className="mb-3">
												<span className="text-danger">*</span>
												<input required className="form-control" name="vat_number" type="text"
													value={formState?.vat_number}
													onChange={updateForm}
													placeholder="VAT Number"
												/>
											</div>
										</div>
										<div className="form-group col-md-6">
											<div className="mb-3">
												<span className="text-danger">*</span>
												<input required className="form-control" name="address_line1" type="text"
													placeholder="Address Line1"
													value={formState?.address_line1}
													onChange={updateForm}
												/>
											</div>
										</div>
										<div className="form-group col-md-6">
											<div className="mb-3">
												<span className="text-danger">*</span>
												<input required className="form-control" name="address_line2" type="text"
													placeholder="Address Line2"
													value={formState?.address_line2}
													onChange={updateForm}
												/>
											</div>
										</div>
										<div className="form-group col-md-6">
											<div className="mb-3">
												<span className="text-danger">*</span>
												<input required className="form-control" name="city" type="text"
													placeholder="City"
													value={formState?.city}
													onChange={updateForm}
												/>
											</div>
										</div>
										<div className="form-group col-md-6">
											<div className="mb-3">
												<span className="text-danger">*</span>
												<input required className="form-control" name="country" type="text"
													placeholder="Country"
													value={formState?.country}
													onChange={updateForm}
												/>
											</div>
										</div>

										<div className="form-group col-md-6">
											<div className="mb-3">
												<span className="text-danger">*</span>
												<input required className="form-control" name="password" type="password"
													placeholder="Password"
													value={formState?.password}
													onChange={updateForm}
												/>
											</div>
										</div>

										<div className="col-md-12">
											{/* <Widget onChange={uploadImage} publicKey={UPLOAD_CARE_PUBLIC_KEY} />  */}
											{/* <input type="file" name="company_logo" onchange="return validate()" placeholder="Choose image" id="image"/> */}
										</div>
										<br />
										<hr></hr>
										<div className="container">
											<table>
												<thead>
													<tr>
														<th>Feature</th>
														<th style={{ float: 'right' }}>Action</th>
													</tr>
												</thead>
												<tbody>
													{features.map((feature, key) => (
														<tr key={key}>
															<td>{feature.feature.charAt(0).toUpperCase() + feature.feature.slice(1)}</td>
															<td style={{ marginLeft: '150px' }}>
																<input
																	name={feature.feature}
																	value={!feature.active}
																	type="checkbox"
																	onChange={onChangeHandler}
																/>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
							<button type="submit" className="btn right btn-primary" onClick={() => createCompaniesAction()}>
								Add
							</button>
						</div>
					</div>
				</div>
			</div>
			{/* update modal */}

			<div className="modal fade" id="editModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">Edit Company</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
						</div>
						{/* <EditCompanies company={companyData} /> */}
					</div>
				</div>
			</div>
		</>
	);
}

export default Companies;