import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'material-react-toastify';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getUser } from '../../../config/common';
import { Link, useHistory } from 'react-router-dom';
import { subscriberMail } from '../../../services/mail/sendMail';

const Mail = () => {
	const history = useHistory();
	const [user, setCurrentUser] = useState([]);
	const [formState, setFormState] = useState({
		emails: '',
        subject: '',
        message: '',
        role: '',
        adminEmail: '',
        adminName: '',
	});

	useEffect(() => {
		async function fetchData() {
			const user = await getUser();
			if (user) {
				setCurrentUser(user)
			}
		}
		fetchData();
	}, []);

    const sendMail = async () => {
        // const emails = formState.emails.split(',');
        const adminEmail = formState.adminEmail;
        const adminEmailDomain = adminEmail.split('@')[1];
        if (adminEmailDomain !== 'irunauto.com') {
            toast.error('Please enter a valid admin email');
            return;
        }

        const body = {
            emails: formState.emails,
            subject: formState.subject,
            message: formState.message,
            role:  formState.role,
            adminEmail: formState.adminEmail,
            adminName: formState.adminName,
        }

        if (body.emails === '' || body.subject === '' || body.adminEmail === '' || body.adminName === '' ||  body.message === ''  || body.role === '') {
            toast.error('Please fill all the fields');
            return;
        }

        const response = await subscriberMail(body);

        if (response.status === 200) {
            toast.success("Email sent successfully");
            setFormState({
                emails: '',
                subject: '',
                message: '',
                name: '',
                role: '',
                adminEmail: '',
                adminName: '',

            })
        } else {
            toast.error("Error sending email");
        }
    }


    const updateForm = (e) => {
        const { value, name } = e.target;
        setFormState({
            ...formState,
            [name]: value,
        });
    };

    return (
		<>
			<div>
				<ToastContainer />
				<div className='container'>
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center">
							<ul className="nav nav-tabs page-header-tab">
								<li className="nav-item">

									<Link onClick={() => history.goBack()} className="nav-link active">
										<i className="fa fa-arrow-left"></i>
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="section-body mt-3">
					<div className="container-fluid">
						<div className="tab-content mt-3">
							<div className="tab-pane fade show active" id="user-list" role="tabpanel">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Email Sender</h3>
										<div className="card-options">
										</div>
									</div>

									<div className="card-body">
										<div className="row clearfix">
											<div className="col-lg-12 col-md-12 col-sm-12">
												<div className="form-group">
                                                    <label className="form-label">  Emails <small className='text-warning'>[separate with ,]</small></label>
													<textarea className="form-control" name="emails" onChange={updateForm} value={formState.emails} rows="2" placeholder="Enter your emails here" />
												</div>
											</div>

                                            <div className="col-lg-6 col-md-6 col-sm-6">
												<div className="form-group">
                                                <label className="form-label">Your Email</label>
                                                <input
														name='adminEmail' value={formState.adminEmail}
														onChange={updateForm}
														type="email"
														className="form-control"
														placeholder="Email *"
													/>
												</div>
											</div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
												<div className="form-group">
                                                <label className="form-label">Your Name</label>
                                                <input
														name='adminName' value={formState.adminName}
														onChange={updateForm}
														type="email"
														className="form-control"
														placeholder=" *"
													/>
												</div>
											</div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
												<div className="form-group">
                                                    <label className="form-label">Email Subject</label>
													<input
														name='subject' value={formState.subject}
														onChange={updateForm}
														type="text"
														className="form-control"
														placeholder="Reaching out... *"
													/>
												</div>
											</div>
                                        
											<div className="col-lg-6 col-md-6 col-sm-6">
												<div className="form-group">
                                                <label className="form-label">Your Role</label>
                                                <input
														name='role' value={formState.role}
														onChange={updateForm}
														type="text"
														className="form-control"
														placeholder="Role *"
													/>
												</div>
											</div>

											<div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <textarea className="form-control" rows="5" placeholder="Body *" name='message' value={formState.message} onChange={updateForm}></textarea>
                                                </div>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12">
												<button type="button" className="btn btn-primary" onClick={()=> sendMail()}>Send Email</button>
											</div>
										</div>
									</div>

								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Mail;