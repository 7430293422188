import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'material-react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import EmptyState from '../../EmptyState';

import { getUser, formatDate } from '../../../config/common';
import { Link, useHistory } from 'react-router-dom';
import { getAllDemos } from '../../../services/demo';

const Demo = () => {
	const history = useHistory();
    const [loading, setLoading] = useState(false);

	const [user, setCurrentUser] = useState([]);
    const [demos, setDemos] = useState([] );
	const [currentPage, setCurrentPage] = useState(1);
    const [DemoPerPage] = useState(10);

    useEffect(() => {
		async function fetchData() {
			const user = await getUser();
			if (user) {
				const response = await getAllDemos();
				setDemos(response);
				setCurrentUser(user)
			}
		}
		fetchData();
	}, []);
    console.log('demos', demos)

	const indexOfLastDemo = currentPage * DemoPerPage;
    const indexOfFirstDemo= indexOfLastDemo - DemoPerPage;
    const currentDemo = demos.slice(indexOfFirstDemo, indexOfLastDemo);

    const paginate = pageNumber => setCurrentPage(pageNumber);
    const nextPage = () => setCurrentPage(currentPage + 1);
    const prevPage = () => setCurrentPage(currentPage - 1);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(demos.length / DemoPerPage); i++) {
        pageNumbers.push(i);
    }
	
	return (
		<>
			<div>
				<ToastContainer />
				<div className='container'>
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center">
							<ul className="nav nav-tabs page-header-tab">
								<li className="nav-item">

									<Link onClick={() => history.goBack()} className="nav-link active">
										<i className="fa fa-arrow-left"></i>
									</Link>
								</li>
							</ul>
							<div className="header-action">
								<button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i className="fe fe-plus mr-2" />Add</button>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-3">
					<div className="container-fluid">
						<div className="tab-content mt-3">
							<div className="tab-pane fade show active" id="user-list" role="tabpanel">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Demo</h3>
										<div className="card-options">
											<form>
												<div className="input-group">
													<input
														type="text"
														className="form-control form-control-sm"
														placeholder="Search something..."
														name="s"
													/>
													<span className="input-group-btn ml-2">
														<button className="btn btn-sm btn-default" type="submit">
															<span className="fe fe-search" />
														</button>
													</span>
												</div>
											</form>
										</div>
									</div>
									
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
												<thead>
													<tr>
														<th>Email</th>
														<th>Notes</th>
														<th>Appointment Date</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{currentDemo.map((demo) => (
														<tr key={demo.id}>
															<td>
																<span>{demo.email}</span>
															</td>
                                                            <td className='td-wrap'>{demo.note}</td>
															<td>{demo.month}/{demo.date}/{demo.year} :: {demo.hour}:{demo.minute}:{demo.second} {demo.meridiem}</td>
															<td> <button  className="btn btn-sm btn-danger">Remove</button></td>
														</tr>
													))}
                                                    
												</tbody>
											</table>
										</div>
									</div>
									
								</div>
								{/* pagination here */}
								<div className=''>
									<nav aria-label="Page navigation example">
										<ul className="pagination justify-content-end">
											<li className="page-item" style={{ marginRight: '5px' }}>
												<button className="btn btn-sm btn-primary" onClick={prevPage} disabled={currentPage === 1 ? true : false}><i className="fa fa-angle-double-left"></i></button>
											</li>
											{pageNumbers.map(number => (
												<li key={number} className="page-item" style={{ marginRight: '5px' }}>
													<button onClick={() => paginate(number)} className={currentPage === number ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-outline-primary'}>{number}</button>
												</li>
											))}
											<li className="page-item">
												<button className="btn btn-sm btn-primary" onClick={nextPage} disabled={currentPage === pageNumbers.length ? true : false}><i className="fa fa-angle-double-right"></i></button>
											</li>
										</ul>
									</nav>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Demo;