/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Piechart from '../../common/piechart';
import Stackedchart from '../../common/stackedchart';
import Barchart from '../../common/barchart';
import { Link, useHistory } from 'react-router-dom';
import { getCompany } from '../../../services/company';
import { getAllSupports } from '../../../services/support';
import { formatDate } from '../../../config/common';

const Company = () => {
	const [company, setCompany] = useState({});

    const [supports, setSupports] = useState([]);

	const id = window.location.pathname.split('/')[3];

	const history = useHistory();

	useEffect(() => {
		async function fetchData() {
			const companyTickets = await getAllSupports(id);
			const response = await getCompany(id);
			if (response) {
				setCompany(response);
				setSupports(companyTickets);
			}
		}
		fetchData();
	}, [id]);

	const features = Object.keys(company.settings?.features || []).map((key) => {
		return { name: key, value: company.settings?.features[key] };
	});

	const navigateToCompanyFeatures = (companyId) => {
		history.push(`/companies/features/${companyId}`);
	}

	const navigateToCompanyTickets = (companyId) => {
		history.push(`/companies/tickets/${companyId}`);
	}
	
	return (
		<>
			<div>
			<div className='container'>
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center">
							<ul className="nav nav-tabs page-header-tab">
								<li className="nav-item">

									<Link onClick={() => history.push(`/companies`)} className="nav-link active">
										<i className="fa fa-arrow-left"></i>
									</Link>
								</li>
							</ul>
							<div className="header-action">
								{/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i className="fe fe-plus mr-2" />Add</button> */}
							</div>
						</div>
					</div>
				</div>
				<div className={`section-body mt-3`}>
					<div className="container-fluid">
						<div className="row clearfix">
							<div className="col-6 col-md-4 col-xl-3">
								<div className="card">
									<div className="card-body ribbon">
										<div className="ribbon-box green">5</div>
										<Link to="/hr-users" className="my_sort_cut text-muted">
											<i className="icon-users" />
											<span>Users</span>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-6 col-md-4 col-xl-3">
								<div className="card">
									<div className="card-body">
										<Link to="/hr-holidays" className="my_sort_cut text-muted">
											<i className="icon-like" />
											<span>Holidays</span>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-6 col-md-4 col-xl-3">
								<div className="card">
									<div className="card-body ribbon">
										<div className="ribbon-box orange">8</div>
										<Link to="/hr-events" className="my_sort_cut text-muted">
											<i className="icon-calendar" />
											<span>Events</span>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-6 col-md-4 col-xl-3">
								<div className="card">
									<div className="card-body">
										<Link to="/hr-payroll" className="my_sort_cut text-muted">
											<i className="icon-credit-card" />
											<span>Payroll</span>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body">
					<div className="container-fluid">
						<div className="row clearfix row-deck">
							<div className="col-xl-8 col-lg-12">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Current Ticket Status</h3>
									</div>

									<div className="card-body">
										<Barchart></Barchart>
									</div>
									<div className="card-footer">
										<div className="row">
											<div className="col-6 col-xl-3 col-md-6">
												<h5>05</h5>
												<div className="clearfix">
													<div className="float-left">
														<strong>35%</strong>
													</div>
													<div className="float-right">
														<small className="text-muted">Yesterday</small>
													</div>
												</div>
												<div className="progress progress-xs">
													<div
														className="progress-bar bg-gray"
														role="progressbar"
														style={{ width: '35%' }}
														aria-valuenow={42}
														aria-valuemin={0}
														aria-valuemax={100}
													/>
												</div>
												<span className="text-uppercase font-10">New Tickets</span>
											</div>
											<div className="col-6 col-xl-3 col-md-6">
												<h5>18</h5>
												<div className="clearfix">
													<div className="float-left">
														<strong>61%</strong>
													</div>
													<div className="float-right">
														<small className="text-muted">Yesterday</small>
													</div>
												</div>
												<div className="progress progress-xs">
													<div
														className="progress-bar bg-gray"
														role="progressbar"
														style={{ width: '61%' }}
														aria-valuenow={42}
														aria-valuemin={0}
														aria-valuemax={100}
													/>
												</div>
												<span className="text-uppercase font-10">Open Tickets</span>
											</div>
											<div className="col-6 col-xl-3 col-md-6">
												<h5>06</h5>
												<div className="clearfix">
													<div className="float-left">
														<strong>100%</strong>
													</div>
													<div className="float-right">
														<small className="text-muted">Yesterday</small>
													</div>
												</div>
												<div className="progress progress-xs">
													<div
														className="progress-bar bg-gray"
														role="progressbar"
														style={{ width: '100%' }}
														aria-valuenow={42}
														aria-valuemin={0}
														aria-valuemax={100}
													/>
												</div>
												<span className="text-uppercase font-10">Solved Tickets</span>
											</div>
											<div className="col-6 col-xl-3 col-md-6">
												<h5>11</h5>
												<div className="clearfix">
													<div className="float-left">
														<strong>87%</strong>
													</div>
													<div className="float-right">
														<small className="text-muted">Yesterday</small>
													</div>
												</div>
												<div className="progress progress-xs">
													<div
														className="progress-bar bg-gray"
														role="progressbar"
														style={{ width: '87%' }}
														aria-valuenow={42}
														aria-valuemin={0}
														aria-valuemax={100}
													/>
												</div>
												<span className="text-uppercase font-10">Unresolved</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-4">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Revenue</h3>
									</div>
									<Piechart></Piechart>

									<div className="card-body text-center">
										<div className="mt-4">
										</div>
										<h3 className="mb-0 mt-3 font300">
											<span className="counter">1,24,301</span>{' '}
											<span className="text-green font-15">+3.7%</span>
										</h3>
										<small>
											Lorem Ipsum is simply dummy text <br />{' '}
										</small>
										{/* <div className="mt-4">
											<span className="chart_3">2,5,8,3,6,9,4,5,6,3</span>
										</div> */}
									</div>
									<div className="card-footer">
										<a href="fake_url" className="btn btn-block btn-success btn-sm">
											Send Report
										</a>
									</div>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6">
							<div className="card">
									<div className="card-header">
										<h3 className="card-title">Tickets</h3>
										<div className="card-options">
											<a href="/#" className="card-options-remove" data-toggle="card-remove">
												<i className="fe fe-x" />
											</a>
											<div className="item-action dropdown ml-2">
												<a href="fake_url" data-toggle="dropdown" aria-expanded="false">
													<i className="fe fe-more-vertical" />
												</a>
												<div
													className="dropdown-menu dropdown-menu-right"
													x-placement="bottom-end"
													style={{
														position: 'absolute',
														transform: 'translate3d(-174px, 25px, 0px)',
														top: 0,
														left: 0,
														willChange: 'transform',
													}}
												>
													<a href="fake_url" className="dropdown-item">
														<i className="dropdown-icon fa fa-eye" /> View Details{' '}
													</a>
													<a href="fake_url" className="dropdown-item">
														<i className="dropdown-icon fa fa-share-alt" /> Share{' '}
													</a>
													<a href="fake_url" className="dropdown-item">
														<i className="dropdown-icon fa fa-cloud-download" /> Download
													</a>
													<div className="dropdown-divider" />
													<a href="fake_url" className="dropdown-item">
														<i className="dropdown-icon fa fa-copy" /> Copy to
													</a>
													<a href="fake_url" className="dropdown-item">
														<i className="dropdown-icon fa fa-folder" /> Move to
													</a>
													<a href="fake_url" className="dropdown-item">
														<i className="dropdown-icon fa fa-edit" /> Rename
													</a>
													<a href="fake_url" className="dropdown-item">
														<i className="dropdown-icon fa fa-trash" /> Delete
													</a>
												</div>
											</div>
										</div>
									</div>
									<table className="table card-table mt-2">
										<tbody>
											{supports.slice(0, 5).map((support, index) => (
											<tr key={index}>
												<td className="width45">
													<span className="avatar avatar-green">
														<i className="fa fa-support" />
													</span>
												</td>
												<td>
													<p className="mb-0">{support.category}</p>
													<span className="text-muted font-13">
													{support.note}
													</span>
												</td>
												<td className="text-right">
													<p className="mb-0">{formatDate(support.createdAt)}</p>
													{support.status === 'approve' && (
                                                    <span className="badge badge-success">approved</span>
													)}
														{support.status === 'disapprove' && (
															<span className="badge badge-danger">rejected</span>
														)}
														{support.status === 'pending' && (
															<span className="badge badge-grey">pending</span>
														)}
												</td>
											</tr>
											))}
										</tbody>
									</table>
									<div className="card-footer">
										<a href="" onClick={() => navigateToCompanyTickets(company.id)}  className="btn btn-block btn-success btn-sm">
										{'	View All'}
										</a>
									</div>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title" style={{ fontWeight: 600 }}>
											Features
										</h3>
									</div>
									<div className='card-details'>
										<p className='card-details-title'>
											Today
										</p>
									</div>
									<table className="table card-table mt-2">
										<tbody>
											{features.slice(0, 5).map((feature, index) => (
											<tr key={index}>
												<td className="width45">
													{feature.value ? (
														<span className="avatar check-blue">
															<i className="fa fa-check text-white feature-checker" />
														</span>
													) : (
														<span className="avatar check-red">
														</span>
													)}
												</td>
												<td>
													<span className="text-muted">{feature.name.toUpperCase()}</span>
												</td>
												<td className="text-right">
													<span className="text-success font-13">{''}</span>
												</td>
											</tr>
											))}

										</tbody>
									</table>
									<div className="card-footer">
										<a href="" onClick={() => navigateToCompanyFeatures(company.id)}  className="btn btn-block btn-success btn-sm">
										{'	View All'}
										</a>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</>
	);
}
export default Company;
