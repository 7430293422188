import React, { Component } from 'react';
import Menu from './Menu';
import { ToastContainer } from 'material-react-toastify';
export default class Layout extends Component {
	render() {
		// if (!getUser()) {
		// 	  this.props.history.push("/login");
		// }
		return (
			<div id="main_content">
			<ToastContainer />
				<Menu {...this.props} />
			</div>
		);
	}
}
