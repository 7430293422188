import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'material-react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { getUser, formatDate } from '../../../config/common';
import { Link, useHistory } from 'react-router-dom';
import { addSubscriber, deleteSubscriber, getAllSubscribers } from '../../../services/subscribers';

const Subscribers = () => {
	const [user, setCurrentUser] = useState([]);
	const [email, setEmail] = useState('');
  	const [error, setError] = useState('');
  	const [success, setSuccess] = useState('');
	const history = useHistory();
	const [subscribers, setSubscribers] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
    const [SubscriberPerPage] = useState(6);

  const subscribeAction = async () => {
    try {
      if (email === '') {
        setError('Please fill all the fields');
        return;
      }

      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        setError('Please enter valid email');
        return;
      }
      const response = await addSubscriber({ email: email });

      if (response.data.id) {
		setSubscribers([...subscribers, response.data]);
        setSuccess("You have successfully subscribed");
      }

      setEmail('');
    } catch (err) {
      setError("Error occured / User already subscribed !");
      setEmail(email)
    }
  };

  setTimeout(() => {
    setError('');
    setSuccess('');
  }, 10000);

  const updateForm = (e) => {
    const { value } = e.target;
      setEmail(value.toLowerCase());
};

	useEffect(() => {
		async function fetchData() {
			const user = await getUser();
			if (user) {
				const response = await getAllSubscribers();
				setSubscribers(response);
				setCurrentUser(user)
			}
		}
		fetchData();
	}, []);
	console.log('sub', subscribers)

	const indexOfLastSubscriber = currentPage * SubscriberPerPage;
    const indexOfFirstSubscriber= indexOfLastSubscriber - SubscriberPerPage;
    const currentSubscriber = subscribers.slice(indexOfFirstSubscriber, indexOfLastSubscriber);

    const paginate = pageNumber => setCurrentPage(pageNumber);
    const nextPage = () => setCurrentPage(currentPage + 1);
    const prevPage = () => setCurrentPage(currentPage - 1);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(subscribers.length / SubscriberPerPage); i++) {
        pageNumbers.push(i);
    }

	const removeSubscriber = async (userId) => {
		try {

			const response = await deleteSubscriber(userId);
			if (response.message) {
				const newSubscribers = subscribers.filter(user => user.id !== userId);
					setSubscribers(newSubscribers);
					toast.info(response.message);
			}

		} catch (err) {
			toast.error("Error, try again");

		};

	};
	
	return (
		<>
			<div>
				<ToastContainer />
				<div className='container'>
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center">
							<ul className="nav nav-tabs page-header-tab">
								<li className="nav-item">

									<Link onClick={() => history.goBack()} className="nav-link active">
										<i className="fa fa-arrow-left"></i>
									</Link>
								</li>
							</ul>
							<div className="header-action">
								<button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i className="fe fe-plus mr-2" />Add</button>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-3">
					<div className="container-fluid">
						<div className="tab-content mt-3">
							<div className="tab-pane fade show active" id="user-list" role="tabpanel">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Subscribers List</h3>
										<div className="card-options">
											<form>
												<div className="input-group">
													<input
														type="text"
														className="form-control form-control-sm"
														placeholder="Search something..."
														name="s"
													/>
													<span className="input-group-btn ml-2">
														<button className="btn btn-sm btn-default" type="submit">
															<span className="fe fe-search" />
														</button>
													</span>
												</div>
											</form>
										</div>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
												<thead>
													<tr>
														<th>Email</th>
														<th>Appointment Date</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{currentSubscriber.map((user) => (
														<tr key={user.id}>
															<td>
																<span>{user.email}</span>
															</td>
															<td>{formatDate(user.createdAt)}</td>
															<td> <button onClick={() => removeSubscriber(user.id)} className="btn btn-sm btn-danger">Remove</button></td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
								{/* pagination here */}
								<div className=''>
									<nav aria-label="Page navigation example">
										<ul className="pagination justify-content-end">
											<li className="page-item" style={{ marginRight: '5px' }}>
												<button className="btn btn-sm btn-primary" onClick={prevPage} disabled={currentPage === 1 ? true : false}><i className="fa fa-angle-double-left"></i></button>
											</li>
											{pageNumbers.map(number => (
												<li key={number} className="page-item" style={{ marginRight: '5px' }}>
													<button onClick={() => paginate(number)} className={currentPage === number ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-outline-primary'}>{number}</button>
												</li>
											))}
											<li className="page-item">
												<button className="btn btn-sm btn-primary" onClick={nextPage} disabled={currentPage === pageNumbers.length ? true : false}><i className="fa fa-angle-double-right"></i></button>
											</li>
										</ul>
									</nav>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Modal */}
			<div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">Add Subscriber</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className='card-header'>
								{error && <div className="alert alert-warning" role="alert">{error}</div>}
            					{success && <div className="alert alert-success" role="alert">{success}</div>}
								</div>
								<div className="card-body">
									<div className="row clearfix">
										<div className="col-lg-12 col-md-12 col-sm-12">
											<div className="form-group">
												<input
													name='email' value={email}
													onChange={updateForm}
													type="text"
													className="form-control"
													placeholder="Email ID *"
												/>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
							<button type="submit" className="btn right btn-primary" onClick={() => subscribeAction()}>
								Add
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Subscribers;