import React, { useState, useEffect, useCallback } from 'react';
import { toast, ToastContainer } from 'material-react-toastify';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getUser } from '../../../config/common';
import { Link, useHistory } from 'react-router-dom';
import { EditorState } from 'draft-js';
import Image from '../../elements/Image';
import UploadCloudinary from '../../../services/cloudinary/UploadCloudinary';
import { createEvent, getAllEvents } from '../../../services/event';

const Event = () => {
	const history = useHistory();
	const [user, setCurrentUser] = useState([]);
	const [events, setEvents] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [DemoPerPage] = useState(10);
	const [image, setImage] = useState(null);
    const [uploadLoading, setUploadLoading] = useState(false);
	const [editorState, setEditorState] = useState(EditorState.createEmpty())
	const [contentState, setContentState] = useState(EditorState.createEmpty())

	const [formState, setFormState] = useState({
		title: '',
		description: '',
		category: '',
		image: '',
		author: '',
		link: '',
	});

	useEffect(() => {
		async function fetchData() {
			const user = await getUser();
			if (user) {
				const response = await getAllEvents();
				setEvents(response);
				setCurrentUser(user)
			}
		}
		fetchData();
	}, []);

	const createBlogAction = async () => {
        try {
            setFormState({ ...formState });

            const body = {
                title: formState.title,
				description: contentState.blocks,
				category: formState.category,
				image: image,
				author: formState.author,
				link: formState.link,
            }

            if (body.name === '' || body.email === '') {
                toast.error('Please fill all the fields');
                return;
            }
            const response = await createEvent(body);

            if (response.id) {
                    // sendEmail(user.emailAddress, user.name, emailCase.updateUser);
                toast.success("Event created successfully");
				setFormState({
					title: '',
					description: '',
					category: '',
					image: '',
					link: '',
				});

				setContentState(EditorState.createEmpty())
            }
        } catch (err) {
            toast.error("Error, please try again");
            setFormState({ ...formState });
        }

    };


    const updateForm = (e) => {
        const { value, name } = e.target;
        setFormState({
            ...formState,
            [name]: value,
        });
    };

	const onEditorStateChange = (editorState) => {
		setEditorState(editorState)
	  }

	  const onContentStateChange = (editorState) => {
		setContentState(editorState)
	  }

	const handleFile = async e => {
        setUploadLoading(true);
        const file = e.target.files[0];
    
        if (!file.type.match('image.*')) {
          toast.error("Please upload an image file");
          setUploadLoading(false);
          return;
        }
        if (file.size > 5000000) {
          toast.error("Image size should not exceed 5MB");
          setUploadLoading(false);
          return;
        }
        const upload = await UploadCloudinary(file);
        setImage(upload.secure_url);
        setImage(upload.secure_url)
        toast.success("Image uploaded successfully!");
        setUploadLoading(false);
      }
    
      const chooseFile = useCallback(() => {
        const dropArea = document.querySelector(".drop_box");
        const button = dropArea.querySelector("button");
        const input = dropArea.querySelector("input");
        button.onclick();
        input.click();
      }, [])


	const indexOfLastDemo = currentPage * DemoPerPage;
	const indexOfFirstDemo = indexOfLastDemo - DemoPerPage;
	const currentEvent = events.slice(indexOfFirstDemo, indexOfLastDemo);

	const paginate = pageNumber => setCurrentPage(pageNumber);
	const nextPage = () => setCurrentPage(currentPage + 1);
	const prevPage = () => setCurrentPage(currentPage - 1);

	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(events.length / DemoPerPage); i++) {
		pageNumbers.push(i);
	}

	return (
		<>
			<div>
				<ToastContainer />
				<div className='container'>
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center">
							<ul className="nav nav-tabs page-header-tab">
								<li className="nav-item">

									<Link onClick={() => history.goBack()} className="nav-link active">
										<i className="fa fa-arrow-left"></i>
									</Link>
								</li>
							</ul>
							{/* <div className="header-action">
								<button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i className="fe fe-plus mr-2" />Add</button>
							</div> */}
						</div>
					</div>
				</div>
				<div className="section-body mt-3">
					<div className="container-fluid">
						<div className="tab-content mt-3">
							<div className="tab-pane fade show active" id="user-list" role="tabpanel">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Event</h3>
										<div className="card-options">
										</div>
									</div>

									<div className="card-body">
										<div className="row clearfix">
											<div className="col-lg-6 col-md-6 col-sm-6">
												<div className="form-group">
													<input
														name='title' value={formState.title}
														onChange={updateForm}
														type="text"
														className="form-control"
														placeholder="Title *"
													/>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-6">
												<div className="form-group">
													<input
														name='author' value={formState.author}
														onChange={updateForm}
														type="text"
														className="form-control"
														placeholder="Author *"
													/>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-6">
												<div className="form-group">
													<select className="form-control show-tick"
														name='category' value={formState.category}
														onChange={updateForm}
													>
														<option>Select Category</option>
														<option>News</option>
														<option>Tech</option>
														<option>Announcement</option>
													</select>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-6">
												<div className="form-group">
													<input
														name='link' value={formState.link}
														onChange={updateForm}
														type="text"
														className="form-control"
														placeholder="Url *"
													/>
												</div>
											</div>

											<div className="col-lg-12 col-md-12 col-sm-12">
												<span style={{color: 'white'}}>Photo: <span className='text-warning'>Image should not exceed 5MB</span></span>
												<div class="form-group col-md-12">
												{uploadLoading && <p className="text-success">Loading...</p>}
												{image ? <img width="10%" className="img-profile" src={image} alt="" /> : <img className="img-profile" src={image} width="10%" alt="" />
												}
												<div className="drop_box">
													<input name="file" type="file" onChange={handleFile} hidden accept='png, jpg' id="fileID" style={{ display: 'none' }} />
													<button onClick={chooseFile} disabled={uploadLoading && true} className="btn-sm btn-upload"><i className="fa fa-cloud-upload fa-3x" aria-hidden="true"></i></button>
												</div>
												</div>
											</div>

											<div className="col-lg-12 col-md-12 col-sm-12">
											<Editor
												editorState={editorState}
												toolbarClassName="toolbarClassName"
												wrapperClassName="wrapperClassName"
												editorClassName="editorClassName"
												onEditorStateChange={onEditorStateChange}
												onContentStateChange={onContentStateChange}
												toolbar={{
													inline: { inDropdown: true },
													list: { inDropdown: true },
													textAlign: { inDropdown: true },
													link: { inDropdown: true },
													history: { inDropdown: true },
												  }}
												editorStyle={{border: '1px solid #ccc', minHeight: '200px', padding: '10px', marginBottom: '20px'}}
												/>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12">
												<button type="button" className="btn btn-primary" onClick={()=> createBlogAction()}>Create</button>
											</div>
										</div>
									</div>

								</div>

							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-3">
					<div className="container-fluid">
						<div className="tab-content mt-3">
							<div className="tab-pane fade show active" id="user-list" role="tabpanel">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Event</h3>
										<div className="card-options">
											<form>
												<div className="input-group">
													<input
														type="text"
														className="form-control form-control-sm"
														placeholder="Search something..."
														name="s"
													/>
													<span className="input-group-btn ml-2">
														<button className="btn btn-sm btn-default" type="submit">
															<span className="fe fe-search" />
														</button>
													</span>
												</div>
											</form>
										</div>
									</div>

									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
												<thead>
													<tr>
														<th>Title</th>
														<th>Image</th>
														<th>Category</th>
														<th>Likes</th>
														<th>Dislikes</th>
														<th>Author</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{currentEvent.map((event) => (
														<tr key={event.id}>
															<td>
																<span>{event.title}</span>
															</td>
															<td className='td-wrap'>
															<Image
															src={event.image}
															alt={event.title}
															className="img-fluid"
															width={100}
															/>
															</td>
															<td className='td-wrap'>{event.category}</td>
															<td>{event.likes}</td>
															<td>{event.dislikes}</td>
															<td>{event.author}</td>
															<td> <button className="btn btn-sm btn-success">View</button></td>
														</tr>
													))}

												</tbody>
											</table>
										</div>
									</div>

								</div>
								{/* pagination here */}
								<div className=''>
									<nav aria-label="Page navigation example">
										<ul className="pagination justify-content-end">
											<li className="page-item" style={{ marginRight: '5px' }}>
												<button className="btn btn-sm btn-primary" onClick={prevPage} disabled={currentPage === 1 ? true : false}><i className="fa fa-angle-double-left"></i></button>
											</li>
											{pageNumbers.map(number => (
												<li key={number} className="page-item" style={{ marginRight: '5px' }}>
													<button onClick={() => paginate(number)} className={currentPage === number ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-outline-primary'}>{number}</button>
												</li>
											))}
											<li className="page-item">
												<button className="btn btn-sm btn-primary" onClick={nextPage} disabled={currentPage === pageNumbers.length ? true : false}><i className="fa fa-angle-double-right"></i></button>
											</li>
										</ul>
									</nav>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Event;