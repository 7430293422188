/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'material-react-toastify';
import { getCompany, updateFeature } from '../../../services/company';
import Image from '../../elements/Image';

const Features = () => {
    const [company, setCompany] = useState({});

    const id = window.location.pathname.split('/')[3];

    const history = useHistory();

    useEffect(() => {
        async function fetchData() {
            const response = await getCompany(id);
            if (response) {
                setCompany(response);
            }
        }
        fetchData();
    }, [id]);

    const features = Object.keys(company.settings?.features || []).map((key) => {
        return { name: key, value: company.settings?.features[key] };
    });

    const navigateToCompanyDashboard = (companyId) => {
        history.push(`/companies/company-dashboard/${companyId}`);
    }

    const toggleFeature = async (feature) => {
        const updatedFeatures = company.settings.features;
        updatedFeatures[feature] = !updatedFeatures[feature];
        const toggleFeature = await updateFeature({featureName: feature, featureValue: updatedFeatures[feature]}, company.id);
        if (toggleFeature) {
				toast.success("Feature " + feature + " updated successfully");
                setTimeout(() => {
                window.location.reload();
                }, 2000);
        }
	};

    return (
        <>
            <div>
            <div className='container'>
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center">
							<ul className="nav nav-tabs page-header-tab">
								<li className="nav-item">

									<Link onClick={() => navigateToCompanyDashboard(id)} className="nav-link active">
										<i className="fa fa-arrow-left"></i>
									</Link>
								</li>
							</ul>
							<div className="header-action">
								<button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i className="fe fe-plus mr-2" />Add</button>
							</div>
						</div>
					</div>
				</div>
                <div className={`section-body mt-3`}>
                    <div className="container-fluid features ">

                        <div className='d-flex justify-content-between '>
                            <div className=" ">
                                <p className='text'>All Features</p>
                            </div>
                        </div>
                        <div className="row clearfix ">
                            {features.map((feature, index) => (
                                <div key={index} style={{cursor: 'pointer'}} onClick={() => toggleFeature(feature.name)} className="col-12 col-md-6 col-xl-4  features-wrapper">
                                    <div className="features-card">
                                        <div className="card-body feat">
                                            <div className={`circle ${feature.value ? "circle2" : ""}`}>
                                                {feature.value ?
                                                    <Image
                                                        src={require("../../../assets/images/check.png")}
                                                        alt={'active'}
                                                        className="img-fluid"
                                                    />
                                                    : null}
                                            </div>
                                            <p className='text-md' style={{ fontSize: '12px' }}>{feature.name.charAt(0).toUpperCase() + feature.name.slice(1).replace(/([A-Z])/g, ' $1').trim()}</p>
                                            <div className='text-sm d-flex '>
                                                <Image
                                                    src={require("../../../assets/images/arrow.png")}
                                                    alt={'active'}
                                                    className="img-fluid"
                                                />
                                                <span className='percent'>120%</span>
                                                <span>since last year</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}


                        </div>

                    </div>


                </div>
            </div>
        </>
    )
};


export default Features